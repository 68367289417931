import styled from "styled-components"

const CommaList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;

    &:after {
      display: inline;
      content: ", ";
      margin-right: 5px;
    }

    &:last-child:after {
      display: none;
    }
  }
`

export default CommaList
