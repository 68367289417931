import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import ArticlePage from "./abstract-article"
import { blog } from "../articleTypes"
import { getPostLink } from "../utils/post-meta"
import PostFooter from "../components/post-footer"

const PostPage = ({ data, ...props }) => {
  return (
    <ArticlePage
      {...props}
      article={data.post}
      articleType={blog}
      articleTypeTag="post"
      articleTypePath="/blog"
      entryMeta={
        <div>
          {"Daniel Friesen"}
          {" / "}
          <Link to={getPostLink(data.post)}>
            {data.post.frontmatter.textDate}
          </Link>
        </div>
      }
      footer={<PostFooter post={data.post} />}
    />
  )
}
PostPage.propTypes = {
  data: PropTypes.any,
}

export const query = graphql`
  query($slug: String!) {
    post: markdownRemark(
      fields: { contentKind: { eq: "post" }, slug: { eq: $slug } }
    ) {
      ...TinaRemark
      ...ArticlePageFragment
      frontmatter {
        isoDate: date
        textDate: date(formatString: "MMM Do, YYYY")
        tags
      }
    }
  }
`

export default PostPage
