import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { getPostLink } from "../utils/post-meta"
import { CardFooter, CardMetaList } from "../components/card"
import { FaRegCalendarAlt, FaUser, FaTags } from "react-icons/fa"
import CommaList from "../components/comma-list"

const PostFooter = ({ post }) => {
  return (
    <CardFooter variant="primary">
      <CardMetaList>
        <li>
          <FaRegCalendarAlt />
          <Link to={getPostLink(post)}>
            <time
              dateTime={post.frontmatter.isoDate}
              title={post.frontmatter.textDate}
            >
              {post.frontmatter.textDate}
            </time>
          </Link>
        </li>
        <li>
          <FaUser />
          Daniel Friesen
        </li>
        <li>
          {post.frontmatter.tags && (
            <>
              <FaTags />
              <CommaList>
                {post.frontmatter.tags.map(tag => (
                  <li key={tag}>{tag}</li>
                ))}
              </CommaList>
            </>
          )}
        </li>
      </CardMetaList>
    </CardFooter>
  )
}
PostFooter.propTypes = {
  post: PropTypes.any,
}

export default PostFooter
